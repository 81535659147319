import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useSetRecoilState } from "recoil";
import axios from "axios";

import { tokenState } from "../../state";
import { tokenUpdateInApiOption, getTokenWithPayload, api } from "../../utils/util";
import { useCallback, useEffect } from "react";

const Auth = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setToken = useSetRecoilState(tokenState);
  const location = useLocation();
  const param = new URLSearchParams(location && location.search);
  const code = param.get("code");
  const pathname = param.get("pathname");
  const syncType = param.get("syncType");

  const doLogin = useCallback(
    async (tokenData) => {
      if (!tokenData) {
        navigate("/", { replace: true });
        return;
      }

      const decodedData = getTokenWithPayload(tokenData);

      const { id_token, syncableUser } = tokenData;
      if (!decodedData.uid && syncableUser) {
        // 연동가능한 정보가 있을 경우 연동 확인
        navigate("/syncAccount", {
          state: {
            loginType: decodedData.idp,
            accessToken: decodedData.access_token,
            idToken: id_token,
            syncableUser,
          },
          replace: true,
        });
        return;
      }

      // 로그인 처리
      localStorage.clear();
      localStorage.setItem("token", JSON.stringify(decodedData));
      queryClient.clear();
      tokenUpdateInApiOption(tokenData.access_token);
      setToken(decodedData);

      await api.get("/user/searchKey").then((res) => {
        if (res.data.searchKey && res.data.indexes) {
          localStorage.setItem("searchKey", res.data.searchKey);
          localStorage.setItem("searchIndexes", JSON.stringify(res.data.indexes));
        }
      });

      if (decodedData && !decodedData?.uid) {
        navigate("/phoneVerify", { state: true, replace: true });
        return;
      }

      if (!decodedData.user_type) {
        navigate("/verifyPending", { state: true, replace: true });
        return;
      }

      if (pathname) {
        navigate(pathname, { replace: true });
      } else {
        const options = { replace: true };
        if (syncType) {
          options.state = { syncType };
        }
        navigate("/", options);
      }
    },
    [navigate, pathname, queryClient, setToken, syncType],
  );

  const getToken = useCallback(async () => {
    const url = `${import.meta.env.VITE_APP_PUBLIC_API_URL}/community-auth/token`;
    const data = { code };
    const info = await axios
      .post(url, data)
      .then((res) => (res.data?.access_token ? res.data : null))
      .catch((err) => {
        console.error(err);
        return null;
      });
    doLogin(info);
  }, [code, doLogin]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  return <></>;
};

export default Auth;
