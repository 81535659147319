import { useState } from "react";
import SideMenu from "../../component/mypage/SideMenu";
import Loading from "../../component/Loading";
import { api, Img, uidEncode } from "../../utils/util";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import WithdrawPopupBox from "../../component/home/WithdrawPopupBox";
import ChangeNick from "../../component/mypage/ChangeNick";
import Popup from "../../component/Popup";
import ChangePwd from "../../component/mypage/ChangePwd";
import DoctorVerifyPopup from "./DoctorVerifyPopup";
import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { tokenState } from "../../state";
import OwnerBadge from "component/OwnerBadge";
import { useUserInfo } from "api/mypage/hooks";
import Badges from "component/user/badges";

function MyPage() {
  const navigate = useNavigate();
  const [withdrawPopup, setWithdrawPopup] = useState(false);
  const [changeNick, setChangeNick] = useState(false);
  const [changePwd, setChangePwd] = useState(false);
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [logoutAll, setLogoutAll] = useState(false);
  const [registerDistrict, setRegisterDistrict] = useState(false);
  const token = useRecoilValue(tokenState);

  console.log(token);

  const setLogout = () => {
    const data = {
      device: logoutAll ? "all" : null,
    };
    api.post("/user/logout", data);
    navigate("/logout", { replace: true });
  };

  const { data: myInfo, loading: myInfoLoading } = useUserInfo();

  const { data: checkCi, loading: checkCiLoading } = useQuery("checkCi", () =>
    api
      .post("/user/check/ci", {
        idToken: token.id_token,
      })
      .then((res) => res.data),
  );

  const moredenAccount = checkCi?.find((item) => item.serviceName === "moreden");
  const chicruitingAccounts = checkCi?.filter((item) => item.serviceName === "chicruiting-company");
  const isUnified = chicruitingAccounts?.find((item) => item.userId === moredenAccount?.userId);

  if (!myInfo || myInfoLoading) return <Loading />;
  return (
    <div className="container">
      <div className="wrapper">
        <section className="lnb__layout">
          <div className="inner">
            <SideMenu current="mypage" isUnified={isUnified} />
            <div className="con__layout">
              <section className="my__home" style={{ marginBottom: "76.5px" }}>
                <article className="section__tit__box">
                  <div className="tit-box flex">
                    <span className="tit-txt">마이 페이지</span>
                    <button
                      className="profile-preview"
                      onClick={() => navigate(`/profile/${uidEncode(token.uid, myInfo.nickname)}`)}
                    >
                      <i className="ii ii-user"></i>프로필 미리보기
                    </button>
                  </div>
                </article>

                <div className="grade-inner">
                  <div className="grade-box__wrapper my-grade-box__wrapper">
                    <div className="grade-info">
                      <div>
                        <div className="font-bold text-[15px] flex items-center">
                          <span className="mr-2">{myInfo.nickname}</span>
                          <span className="level">Lv {myInfo.user_level}</span>
                          <Badges isOwner={myInfo.is_owner} uid={token.uid} />
                        </div>
                        <div className="detail-info">
                          <span>
                            글 <b>{myInfo.board_cnt}개</b>
                          </span>
                          &nbsp;
                          <span>
                            댓글 <b>{myInfo.comment_cnt}개</b>
                          </span>
                          &nbsp;
                          <span>
                            구독자 <b>{myInfo.subscription_cnt}명</b>
                          </span>
                        </div>
                      </div>
                      <div style={{ position: "relative" }}>
                        <img
                          src={Img("", `img/profile_rank.svg`)}
                          alt="grade"
                          className="profile"
                        />
                        <div className="level_number">{myInfo.user_level}</div>
                      </div>
                    </div>
                    {myInfo.point && (
                      <div
                        className="point-info"
                        onClick={() => navigate("/mypage/point/member_level")}
                      >
                        <div className="title">회원 등급 포인트</div>
                        <div className="point__wrapper">
                          <div>{myInfo.point.total_point}점</div>
                          <img src={Img("", "img/drawable/point_arrow.svg")} alt="arrow" />
                        </div>
                      </div>
                    )}
                  </div>
                  {myInfo.user_type === "doctor" && myInfo.user_rank && myInfo.point && (
                    <>
                      <div className="grade-box__wrapper">
                        <div className="grade-info">
                          <div>
                            <div className="name">
                              <span>Q&amp;A</span>
                              <span className="level">{myInfo.user_rank.qna_rank}위</span>
                            </div>
                            <div className="detail-info">
                              <span>
                                위원회 선정 <b>{myInfo.point.qna_admin_selected}번</b>
                              </span>
                              &nbsp;
                              <span>
                                작성자 선정 <b>{myInfo.point.qna_writer_selected}번</b>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="point-info" onClick={() => navigate("/mypage/point/qna")}>
                          <div className="title">Q&amp;A 포인트</div>
                          <div className="point__wrapper">
                            <div>{myInfo.point.qna_point}점</div>
                            <img src={Img("", "img/drawable/point_arrow.svg")} alt="arrow" />
                          </div>
                        </div>
                      </div>
                      <div className="grade-box__wrapper">
                        <div className="grade-info">
                          <div>
                            <div className="name">
                              <span>임상포럼</span>
                              <span className="level">{myInfo.user_rank.forum_rank}위</span>
                            </div>
                            <div className="detail-info">
                              <span>
                                위원회 선정 <b>{myInfo.point.forum_admin_selected}번</b>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="point-info" onClick={() => navigate("/mypage/point/forum")}>
                          <div className="title">임상포럼 포인트</div>
                          <div className="point__wrapper">
                            <div>{myInfo.point.forum_point}점</div>
                            <img src={Img("", "img/drawable/point_arrow.svg")} alt="arrow" />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <article
                  className="section__tit__box2 border-type1"
                  style={{ marginTop: "20.5px" }}
                >
                  <div className="tit-box">
                    <span className="tit-txt">나의 정보</span>
                  </div>
                </article>

                <article className="set__table">
                  <table>
                    <tbody>
                      <tr>
                        <th className="accent">이름</th>
                        <td>{myInfo.name}</td>
                      </tr>
                      <tr>
                        <th className="accent">아이디</th>
                        <td>
                          {myInfo.id}{" "}
                          <span className="text-xs text-secondary-500">
                            {checkCiLoading ? "" : isUnified ? "통합 ID" : "미통합 ID"}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th className="accent">닉네임</th>
                        <td>
                          {myInfo.nickname}{" "}
                          <button className="set-btn" onClick={() => setChangeNick(true)}>
                            <i className="ic ic-update"></i>
                            <span className="txt">닉네임 변경</span>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th className="accent">휴대폰 번호</th>
                        <td>{myInfo.phone_number}</td>
                      </tr>
                      <tr>
                        <th className="accent">추천인 코드</th>
                        <td>
                          {myInfo.recommend_code}{" "}
                          {navigator.share ? (
                            <span
                              onClick={() =>
                                navigator.share({
                                  title: "모어덴 회원가입",
                                  text: "모어덴 가입하기",
                                  url: `https://www.moreden.co.kr?rcode=${myInfo.recommend_code}`,
                                })
                              }
                            >
                              <img
                                className="inline-block"
                                src={`${
                                  import.meta.env.VITE_APP_PUBLIC_URL
                                }img/drawable/ic_re_share.svg`}
                                alt="이미지"
                              />
                              <span className="share">공유하기</span>
                            </span>
                          ) : (
                            <CopyToClipboard
                              text={`https://www.moreden.co.kr?rcode=${myInfo.recommend_code}`}
                              onCopy={() => alert("링크를 복사하였습니다")}
                            >
                              <span>
                                <img
                                  className="inline-block"
                                  src={`${
                                    import.meta.env.VITE_APP_PUBLIC_URL
                                  }img/drawable/ic_re_share.svg`}
                                  alt="이미지"
                                />
                                <span className="share">공유하기</span>
                              </span>
                            </CopyToClipboard>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="accent">비밀번호 변경</th>
                        <td>
                          <button className="set-btn pwd" onClick={() => setChangePwd(true)}>
                            <i className="ic ic-update"></i>
                            <span className="txt">비밀번호 변경</span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </article>
                {myInfo.user_type === "student" && (
                  <article className="verify-doctor">
                    <div className="title">치과의사 인증하기</div>
                    <div className="grey">
                      하단 버튼을 눌러, 카메라로 의사 면허증을 찍어 업로드 해주세요
                    </div>
                    <b>
                      한번 치과의사 회원 전환 시, 다시는 치대생 회원으로 돌아갈 수 없으며 치대생
                      게시판 접근도 불가합니다.
                    </b>
                    <div className="notice">
                      <img
                        className="certificate_img"
                        src={Img("", "img/img_certificate.png")}
                        alt=""
                      />
                      <div className="grey notice_right">
                        <div>
                          1. 정확하지 않은 사진은 등록이 거절될 수 있으니, 반드시 식별 가능하게
                          찍어주세요.
                        </div>
                        <div>2. 승인까지는 영업일 기준 최대 3일이 소요될 수 있습니다.</div>
                        <DoctorVerifyPopup>
                          <button>
                            <img src={Img("", "img/camera_icon.svg")} alt="" />
                            면허 등록하기
                          </button>
                        </DoctorVerifyPopup>
                      </div>
                    </div>
                  </article>
                )}
                <div className="my__home__menu">
                  <button className="menu-btn" onClick={() => setLogoutPopup(true)}>
                    로그아웃
                  </button>
                  <button className="menu-btn" onClick={() => setWithdrawPopup(true)}>
                    회원탈퇴
                  </button>
                  {false && <button className="menu-btn type1">확인</button>}
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
      {withdrawPopup && <WithdrawPopupBox setPopup={setWithdrawPopup} />}
      {changeNick && <ChangeNick setPopup={setChangeNick} />}
      {changePwd && <ChangePwd setPopup={setChangePwd} />}
      {logoutPopup && (
        <Popup
          setPopup={setLogoutPopup}
          title="로그아웃"
          setConfirm={setLogout}
          confirmBtn="로그아웃"
        >
          <div>로그아웃 하시겠습니까?</div>
          <div className="cbox">
            <label>
              <input type="checkbox" onChange={(e) => setLogoutAll(e.target.checked)} />
              <i></i>
              <span className="txt">모든 기기에서 로그아웃</span>
            </label>
          </div>
        </Popup>
      )}
    </div>
  );
}

export default MyPage;
