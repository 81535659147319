import { useQuery } from "react-query";
import { fetchUserBadges } from "./api";
import { tokenState } from "state";
import { useRecoilValue } from "recoil";

export const useUserBadgesQuery = (uid: number) => {
  const hasUid = uid && uid > 0 ? true : false;
  const token = useRecoilValue(tokenState);

  return useQuery(["userBadges", uid], () => fetchUserBadges(uid), {
    staleTime: 15 * 60 * 1000,
    enabled: hasUid && !!token,
  });
};
