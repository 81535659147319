import { CellSelection, addColumnAfter, addColumnBefore, addRowAfter, addRowBefore, columnResizing, deleteColumn, deleteRow, goToNextCell, mergeCells, setCellAttr, splitCell, tableEditing, toggleHeaderColumn, toggleHeaderRow } from '@medistream/prosemirror-tables';
import { findParentNodeOfTypeClosestToPos, isNodeSelection } from 'prosemirror-utils'
import { NodeSelection, Plugin, PluginKey, TextSelection } from 'prosemirror-state'
import { tableNodes } from '@medistream/prosemirror-tables'
import { Fragment } from 'prosemirror-model'
import { Extension } from "../core/extension";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { GapCursor } from 'prosemirror-gapcursor';

export const CELL_TOOLTIP_PLUGIN_KEY = new PluginKey('cell_tooltip')

/**
 * prosemirror-tables 의 table 노드 스펙을 변경합니다.
 * 
 * 참고: https://github.com/ProseMirror/prosemirror-tables/blob/8ec1e96ae994c1585b07b1ca9dc3292f63e868e0/src/schema.ts#L133-L142
 *
 * @param {() => import('@medistream/prosemirror-tables').TableNodes} tableNodes
 */
const customizeTableNodeSpec = tableNodes => {
  const tableNodeSet = tableNodes({
    tableGroup: 'block',
    cellContent: 'block+',
    cellAttributes: {
      class: {
        default: MEDISTREAM_SCHEMA_STYLE.nodes.tableCell,
        setDOMAttr: (value, attrs) => {
          if (value) {
            attrs.class = value
          }
        },
      },
      background: {
        default: null,
        getFromDOM(dom) {
          if (dom.style.backgroundColor.startsWith('var')) {
            return dom.style.backgroundColor || null
          }
        },
        setDOMAttr(value, attrs) {
          if (value)
            attrs.style = (attrs.style || '') + `background-color: ${value};`
        },
      },
      style: {
        default: null,
        setDOMAttr: (value, attrs) => {
          if (attrs['data-colwidth']) {
            attrs.style =
              (attrs.style || '') + `width: ${attrs['data-colwidth']}px;`
          }
        },
      },
    },
  })

  tableNodeSet.table.attrs = {
    ...tableNodeSet.table.attrs,
    style: {
      default: null,
    },
    class: {
      default: MEDISTREAM_SCHEMA_STYLE.nodes.table,
    }
  }

  tableNodeSet.table.parseDOM = [
    {
      tag: 'table',
      getAttrs(dom) {
        const classNames = [MEDISTREAM_SCHEMA_STYLE.nodes.table]
        if (dom.classList.contains('transparent')) {
          classNames.push('transparent')
        }
        return {
          class: classNames.join(' '),
          style: dom.getAttribute('style') || ''
        }
      }
    }
  ]

  return tableNodeSet
}

const tableFamilySpecs = customizeTableNodeSpec(tableNodes)

/**
 *
 * @param {import('prosemirror-state').EditorState} state
 * @param {() => void} dispatch
 * @returns
 */
const insertTable = (state, dispatch) => {
  const offset = state.tr.selection.anchor + 1
  const transaction = state.tr
  const cell = state.schema.nodes.table_cell.create(
    null,
    Fragment.from(state.schema.nodes.paragraph.create()),
  )
  const node = state.schema.nodes.table.create(
    null,
    Fragment.fromArray([
      state.schema.nodes.table_row.create(
        null,
        Fragment.fromArray([cell, cell]),
      ),
      state.schema.nodes.table_row.create(
        null,
        Fragment.fromArray([cell, cell]),
      ),
    ]),
  )

  if (dispatch) {
    dispatch(
      transaction
        .replaceSelectionWith(node)
        .scrollIntoView()
        .setSelection(TextSelection.near(transaction.doc.resolve(offset))),
    )
  }

  return true
}

/**
 * 콜아웃은 셀 한개짜리 네모 박스입니다.
 * 
 * @type {import('prosemirror-state').Command}
 */
const addCallout = (state, dispatch, view) => {
  const offset = state.tr.selection.anchor + 1
  const transaction = state.tr
  const cell = state.schema.nodes.table_cell.create(
    null,
    Fragment.from(state.schema.nodes.paragraph.create()),
  )
  const node = state.schema.nodes.table.create(
    null,
    Fragment.fromArray([
      state.schema.nodes.table_row.create(
        null,
        Fragment.fromArray([cell]),
      ),
    ]),
  )

  if (dispatch) {
    dispatch(
      transaction
        .replaceSelectionWith(node)
        .scrollIntoView()
        .setSelection(TextSelection.near(transaction.doc.resolve(offset))),
    )
  }

  return true
}

/**
 * 테이블의 모든 테두리를 제거/복원 하는 명령입니다.
 * 
 * @type {import('prosemirror-state').Command}
 */
const toggleCellBorder = (state, dispatch, view) => {
  const tr = state.tr

  if (isNodeSelection(state.selection)) {
    if (state.selection.node.type === state.schema.nodes.table) {
      const className = state.selection.node.attrs.class?.includes('transparent')
        ? MEDISTREAM_SCHEMA_STYLE.nodes.table
        : `${MEDISTREAM_SCHEMA_STYLE.nodes.table} transparent`

      tr.setNodeMarkup(state.selection.from, null, {
        ...state.selection.node.attrs,
        class: className,
      })

      dispatch(tr)
      return true
    }
  }
  
  const tableNode = findParentNodeOfTypeClosestToPos(
    state.doc.resolve(state.selection.anchor),
    state.schema.nodes.table,
  )

  if (!tableNode) return false

  const className = tableNode.node.attrs.class?.includes('transparent')
    ? MEDISTREAM_SCHEMA_STYLE.nodes.table
    : `${MEDISTREAM_SCHEMA_STYLE.nodes.table} transparent`

  tr.setNodeMarkup(tableNode.pos, null, {
    ...tableNode.node.attrs,
    class: className,
  })

  dispatch(tr)
  return true
}

class CellTooltipPluginView {
  /**
   *
   * @param {import('prosemirror-view').EditorView} view
   */
  constructor(view) {
    this.view = view
    this.CSS = MEDISTREAM_SCHEMA_STYLE.etc.cellMenu

    this.tooltip = document.createElement('div')
    this.tooltip.classList.add(this.CSS)

    this.buttonContainer = document.createElement("div");
    this.buttonContainer.classList.add(`${this.CSS}__buttonContainer`)

    this.button = document.createElement('button')
    this.button.type = 'submit'
    this.button.classList.add("menuButton")
    this.button.innerHTML = '<svg width="16" height="13" viewBox="5 6 16 13" role="presentation"><path d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z" fill="currentColor" fill-rule="evenodd"></path></svg>'

    this.buttonContainer.appendChild(this.button)

    this.tableMenu = document.createElement("ul")
    this.tableMenu.classList.add(`${this.CSS}__tableMenu`)
    this.tableMenu.style.display = "none"

    this.addTable = document.createElement("li")
    this.addTable.classList.add(`${this.CSS}__item`)
    this.addTable.textContent = "테이블 추가"
    this.addTable.addEventListener('click', (event) => {
      execMenuFunc(event, () => insertTable(view.state, view.dispatch))
    })

    this.cellBgColor = document.createElement("li")
    this.cellBgColor.classList.add(`${this.CSS}__item`)
    this.cellBgColor.textContent = "셀 배경색"
    this.cellBgColor.addEventListener('mouseover', this._showPalette.bind(this))
    this.cellBgColor.addEventListener('mouseout', this._hidePalette.bind(this))

    this.palette = document.createElement("div")
    this.palette.classList.add(`${this.CSS}__palette`)

    const colors = this.view.someProp('colorScheme')

    if (!colors) throw 'CellTooltipPlugin 을 사용하려면 Editor Props 에 colorScheme 을 주입해주세요.'

    const cellColors = [
      ...colors.tableCellBackgroundColors,
      ['reset-style', 'reset-style']
    ]

    for (let i = 0; i < cellColors.length; i++) {
      this.figure = document.createElement("figure")
      if (i === cellColors.length - 1) {
        this.figure.classList.add(`${this.CSS}__palette-resetStyle`)
      }
      this.figure.classList.add(`${this.CSS}__palette-cellBgColor`)
      this.figure.dataset.color = cellColors[i][1]
      this.figure.style.backgroundColor = cellColors[i][1]
      this.figure.addEventListener('click', (event) => {
        event.stopPropagation()
        this._selectColor(event)
        this.view.dom.focus()
      })
      this.palette.appendChild(this.figure)
    }

    this.cellBgColor.appendChild(this.palette)

    this.border = document.createElement("li")
    this.border.classList.add(`${this.CSS}__item`)
    this.border.innerHTML = "테두리 켜기/끄기<code>Ctrl+Alt+ㅁ</code>"
    this.border.addEventListener('click', (event) => {
      execMenuFunc(event, () => toggleCellBorder(view.state, view.dispatch, view))
    })

    this.mergeCells = document.createElement("li")
    this.mergeCells.classList.add(`${this.CSS}__item`)
    this.mergeCells.textContent = "셀 합치기"
    this.mergeCells.addEventListener('click', (event) => {
      execMenuFunc(event, () => mergeCells(view.state, view.dispatch))
    })

    this.splitCell = document.createElement("li")
    this.splitCell.classList.add(`${this.CSS}__item`)
    this.splitCell.textContent = "셀 나누기"
    this.splitCell.addEventListener('click', (event) => {
      execMenuFunc(event, () => splitCell(view.state, view.dispatch))
    })

    this.headerRow = document.createElement("li")
    this.headerRow.classList.add(`${this.CSS}__item`)
    this.headerRow.textContent = "헤더 행으로 전환"
    this.headerRow.addEventListener('click', (event) => {
      execMenuFunc(event, () => toggleHeaderRow(view.state, view.dispatch))
    })

    this.headerColumn = document.createElement("li")
    this.headerColumn.classList.add(`${this.CSS}__item`)
    this.headerColumn.textContent = "헤더 열로 전환"
    this.headerColumn.addEventListener('click', (event) => {
      execMenuFunc(event, () => toggleHeaderColumn(view.state, view.dispatch))
    })

    this.removeRow = document.createElement("li")
    this.removeRow.classList.add(`${this.CSS}__item`)
    this.removeRow.innerHTML = "행 삭제<code>Ctrl+Alt+ㅡ</code>"
    this.removeRow.addEventListener('click', (event) => {
      execMenuFunc(event, () => deleteRow(view.state, view.dispatch))
    })

    this.removeColumn = document.createElement("li")
    this.removeColumn.classList.add(`${this.CSS}__item`)
    this.removeColumn.innerHTML = "열 삭제<code>Ctrl+Alt+ㅣ</code>"
    this.removeColumn.addEventListener('click', (event) => {
      execMenuFunc(event, () => deleteColumn(view.state, view.dispatch))
    })

    this.addRowAbove = document.createElement("li")
    this.addRowAbove.classList.add(`${this.CSS}__item`)
    this.addRowAbove.innerHTML = "위에 행 추가<code>Ctrl+Alt+↑</code>"
    this.addRowAbove.addEventListener('click', (event) => {
      execMenuFunc(event, () => addRowBefore(view.state, view.dispatch))
    })

    this.addRowBelow = document.createElement("li")
    this.addRowBelow.classList.add(`${this.CSS}__item`)
    this.addRowBelow.innerHTML = "아래에 행 추가<code>Ctrl+Alt+↓</code>"
    this.addRowBelow.addEventListener('click', (event) => {
      execMenuFunc(event, () => addRowAfter(view.state, view.dispatch))
    })

    this.addColumnRight = document.createElement("li")
    this.addColumnRight.classList.add(`${this.CSS}__item`)
    this.addColumnRight.innerHTML = "우측에 열 추가<code>Ctrl+Alt+→</code>"
    this.addColumnRight.addEventListener('click', (event) => {
      execMenuFunc(event, () => addColumnAfter(view.state, view.dispatch))
    })

    this.addColumnLeft = document.createElement("li")
    this.addColumnLeft.classList.add(`${this.CSS}__item`)
    this.addColumnLeft.innerHTML = "좌측에 열 추가<code>Ctrl+Alt+←</code>"
    this.addColumnLeft.addEventListener('click', (event) => {
      execMenuFunc(event, () => addColumnBefore(view.state, view.dispatch))
    })

    const execMenuFunc = (event, menuFunc) => {
      event.stopPropagation()
      menuFunc()
      this.view.dom.focus()
      this.tableMenu.style.display = 'none'
      this.tooltip.style.display = 'none'
    }

    this.tableMenu.appendChild(this.addTable)
    this.tableMenu.appendChild(this.cellBgColor)
    this.tableMenu.appendChild(this.border)
    this.tableMenu.appendChild(this.mergeCells)
    this.tableMenu.appendChild(this.splitCell)
    this.tableMenu.appendChild(this.headerRow)
    this.tableMenu.appendChild(this.headerColumn)
    this.tableMenu.appendChild(this.removeRow)
    this.tableMenu.appendChild(this.removeColumn)
    this.tableMenu.appendChild(this.addRowAbove)
    this.tableMenu.appendChild(this.addRowBelow)
    this.tableMenu.appendChild(this.addColumnRight)
    this.tableMenu.appendChild(this.addColumnLeft)

    this.tooltip.appendChild(this.buttonContainer)
    this.tooltip.appendChild(this.tableMenu)

    view.dom.parentNode.appendChild(this.tooltip)

    this.tooltip.style.display = "none"

    this.button.addEventListener('click', (event) => {
      event.stopPropagation()
      this._onSubmit(event)
    })

    this.__hideTooltip = this._hideTooltip.bind(this)

    document.addEventListener('mousedown', this.__hideTooltip)
  }

  /**
   *
   * @param {SubmitEvent} event
   */
  _onSubmit(event) {
    event.preventDefault()

    this.tableMenu.style.display = "block"
  }

  _showTooltip() {
    const view = this.view
    const state = view.state
    const selection = state.selection

    this.tableMenu.style.display = 'none'

    if (selection instanceof NodeSelection || selection instanceof GapCursor) {
      this.tooltip.style.display = 'none'
      return
    }

    function isCursorInTable(state) {
      const { $anchor } = state.selection
      return !!$anchor.node(-1).type.spec.tableRole
    }

    const cursorInTable = isCursorInTable(state)

    if (!cursorInTable) return

    const pos = selection.$head.pos

    const { $anchor, $head } = selection
    const selectedCell =
      (!$anchor.nodeAfter || !$anchor.nodeBefore) ||
        (!$head.nodeAfter || !$head.nodeBefore)
        ? view.domAtPos(pos).node
        : view.domAtPos(pos).node.parentNode

    this.tooltip.style.display = 'block'
    this.tooltip.style.position = "absolute"
    const box = this.tooltip.offsetParent.getBoundingClientRect()

    let tooltipLeft
    if (selection instanceof CellSelection) {
      const selectedCellPos = selectedCell.getBoundingClientRect()
      const cellWidth = selectedCellPos.width
      tooltipLeft = selectedCellPos.x + cellWidth - box.left - 24
      this.tooltip.style.left = tooltipLeft + 'px'
      this.tooltip.style.bottom = box.bottom - selectedCellPos.y - 24 + 'px'
    } else {
      const selectedCellPos = selectedCell.offsetParent.getBoundingClientRect()
      const cellWidth = selectedCellPos.width
      tooltipLeft = selectedCellPos.x + cellWidth - box.left - 24
      this.tooltip.style.left = tooltipLeft + 'px'
      this.tooltip.style.bottom = box.bottom - selectedCellPos.y - 24 + 'px'
    }

    const editorBox = document.querySelector('.integration-editor').getBoundingClientRect()
    if (tooltipLeft > editorBox.width + 16) {
      this.tooltip.style.display = 'none'
    }
  }

  /**
   *
   * @param {MouseEvent} event
   */
  _hideTooltip(event) {
    event.stopPropagation()

    const view = this.view
    const selection = view.state.selection
    const pos = selection.$head.pos

    if (
      view &&
      view.domAtPos(pos).node === event.target ||
      view.domAtPos(pos).node.parentElement === event.target
    ) {
      this.tableMenu.style.display = "none";
      return;
    }

    if (event.target.closest(`.${this.CSS}`)) return

    this.tooltip.style.display = 'none'
    this.tableMenu.style.display = 'none'
  }

  _showPalette(event) {
    event.stopPropagation()
    this.palette.style.display = 'grid'
  }

  _hidePalette(event) {
    event.stopPropagation()
    this.palette.style.display = 'none'
  }

  _selectColor(event) {
    event.preventDefault()
    const colorData = event.target.getAttribute("data-color")
    const color = colorData === 'reset-style' ? '' : colorData
    this.tableMenu.style.display = 'none'
    return setCellAttr('background', color)(this.view.state, this.view.dispatch, this.view)
  }

  /**
   *
   * @param {import('prosemirror-view').EditorView} view
   * @returns
   */
  update(view) {
    if (CELL_TOOLTIP_PLUGIN_KEY.getState(view.state) === 'open') {
      this._showTooltip()
      return
    }
  }

  destroy() {
    document.removeEventListener('mousedown', this.__hideTooltip)
  }
}

export const cellTooltip = new Plugin({
  key: CELL_TOOLTIP_PLUGIN_KEY,
  state: {
    init() {
      return undefined
    },
    apply(tr) {
      const meta = tr.getMeta(this)
      if (meta) {
        return meta
      }
    },
  },
  view(editorView) {
    return new CellTooltipPluginView(editorView)
  },
})

export const Table = Extension.Create({
  name: 'table',

  type: 'node',

  defineSpec() {
    return tableFamilySpecs.table
  },

  addCommands() {
    return {
      insertTable,
      addCallout,
      toggleCellBorder,
      addColumnAfter,
      addColumnBefore,
      addRowAfter,
      addRowBefore,
      deleteColumn,
      deleteRow,
      mergeCells,
      setCellAttr,
      splitCell,
      toggleHeaderColumn,
      toggleHeaderRow,
      goToNextCell,
    }
  },

  addPlugins() {
    return [
      cellTooltip,
      columnResizing({
        lastColumnResizable: true,
        handleWidth: 8,
        cellMinWidth: 10,
        wrapperClassNames: [MEDISTREAM_SCHEMA_STYLE.etc.tableWrapper, MEDISTREAM_SCHEMA_STYLE.etc.selectable]
      }),
      tableEditing({ allowTableNodeSelection: true })]
  },

  addKeyboardShortcuts() {
    return {
      Tab: this.editor.commands.goToNextCell(1),
      'Shift-Tab': this.editor.commands.goToNextCell(-1),
      'Ctrl-Alt-z': this.editor.commands.addCallout,
      'Ctrl-Alt-Z': this.editor.commands.addCallout,
      'Ctrl-Alt-ArrowRight': this.editor.commands.addColumnAfter,
      'Ctrl-Alt-ArrowLeft': this.editor.commands.addColumnBefore,
      'Ctrl-Alt-ArrowUp': this.editor.commands.addRowBefore,
      'Ctrl-Alt-ArrowDown': this.editor.commands.addRowAfter,
    }
  }
})

export const TableCell = Extension.Create({
  name: 'table_cell',

  type: 'node',

  defineSpec() {
    return tableFamilySpecs.table_cell
  }
})

export const TableRow = Extension.Create({
  name: 'table_row',

  type: 'node',

  defineSpec() {
    return tableFamilySpecs.table_row
  }
})

export const TableHeader = Extension.Create({
  name: 'table_header',

  type: 'node',

  defineSpec() {
    return tableFamilySpecs.table_header
  }
})

export const TableExtensions = [
  Table,
  TableCell,
  TableRow,
  TableHeader,
]
